var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', {
    attrs: {
      "header-class": "p-1",
      "no-body": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100 d-flex justify-content-between cursor-pointer",
          on: {
            "click": _vm.handleToggleShow
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('IconSvg', {
          staticClass: "mr-25",
          attrs: {
            "src": require('@icons/filter_big.svg'),
            "size": "24px"
          }
        }), _c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(" " + _vm._s(_vm.$t('paginationText.showing')) + " ")])], 1), _c('IconSvg', {
          staticClass: "rotate_class text-secondary",
          style: {
            transform: _vm.rotateStyleVisible,
            width: '12px'
          },
          attrs: {
            "src": require('@icons/triangle-down.svg'),
            "blank-color": "#ccc"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-collapse', {
    staticClass: "mx-1 mb-1",
    model: {
      value: _vm.isVisible,
      callback: function callback($$v) {
        _vm.isVisible = $$v;
      },
      expression: "isVisible"
    }
  }, [_c('div', {
    staticClass: "mb-1 text-center"
  }, [_c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-customer-service-fee",
      modifiers: {
        "modal-customer-service-fee": true
      }
    }],
    staticClass: "rounded-lg py-75",
    attrs: {
      "size": "sm",
      "variant": "outline-warning"
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "EditIcon",
      "size": "12"
    }
  }), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v(_vm._s(_vm.$t('flight.setServiceFee')))])], 1)], 1), _c('div', [_c('h5', [_vm._v(_vm._s(_vm.$t('flight.sortBy')))]), _c('v-select', {
    attrs: {
      "value": _vm.sortBySelected,
      "reduce": function reduce(val) {
        return val.value;
      },
      "label": "label",
      "options": _vm.sortItinerariesTypeOptions,
      "clearable": false
    },
    on: {
      "input": _vm.handleSelectSortBy
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('small', {
          staticClass: "font-weight-bolder text-truncate"
        }, [_vm._v(_vm._s(_vm.$t("flight.viewConfig.".concat(data.label))))])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('small', {
          staticClass: "font-weight-bolder text-truncate"
        }, [_vm._v(_vm._s(_vm.$t("flight.viewConfig.".concat(data.label))))])];
      }
    }, {
      key: "spinner",
      fn: function fn(_ref) {
        var loading = _ref.loading;
        return [loading ? _c('div', {
          staticClass: "vs__spinner",
          staticStyle: {
            "border-left-color": "rgba(88, 151, 251, 0.71)"
          }
        }) : _vm._e()];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }])
  })], 1), _c('div', {
    staticClass: "w-100 mt-75"
  }, [_c('h5', {
    staticClass: "my-50"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.showPrice')) + " ")]), _c('v-select', {
    staticClass: "w-100",
    attrs: {
      "value": _vm.showPrice,
      "reduce": function reduce(val) {
        return val.value;
      },
      "label": "label",
      "options": _vm.showPriceOptions,
      "clearable": false
    },
    on: {
      "input": _vm.handleSelectShowPrice
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('small', {
          staticClass: "font-weight-bolder text-truncate"
        }, [_vm._v(_vm._s(_vm.$t("flight.viewConfig.".concat(data.label))))])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('small', {
          staticClass: "font-weight-bolder text-truncate"
        }, [_vm._v(_vm._s(_vm.$t("flight.viewConfig.".concat(data.label))))])];
      }
    }, {
      key: "spinner",
      fn: function fn(_ref2) {
        var loading = _ref2.loading;
        return [loading ? _c('div', {
          staticClass: "vs__spinner",
          staticStyle: {
            "border-left-color": "rgba(88, 151, 251, 0.71)"
          }
        }) : _vm._e()];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }])
  })], 1), !_vm.isRoleF1 && (_vm.showPrice === 'TOTAL_FARE' || _vm.showPrice === 'NET_FARE') ? _c('div', {
    staticClass: "d-flex-center justify-content-start mt-75"
  }, [_c('BFormCheckbox', {
    staticClass: "custom-control-warning",
    attrs: {
      "checked": _vm.showPriceAfterDiscount,
      "switch": ""
    },
    on: {
      "change": _vm.handleSetShowPriceAfterDiscount
    }
  }), _c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.viewConfig.discountedPrice')) + " ")])], 1) : _vm._e(), _vm.showPrice === 'TOTAL_FARE' ? _c('div', {
    staticClass: "d-flex-center justify-content-start mt-75"
  }, [_c('BFormCheckbox', {
    staticClass: "custom-control-warning",
    attrs: {
      "checked": _vm.showServiceFee,
      "switch": ""
    },
    on: {
      "change": _vm.handleToggleShowServiceFee
    }
  }), _c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.viewConfig.showFeeService')) + " ")])], 1) : _vm._e(), !_vm.isRoleF1 && _vm.showPrice === 'TOTAL_FARE' && !_vm.getHideFee ? _c('div', {
    staticClass: "d-flex-center justify-content-start mt-75"
  }, [_c('BFormCheckbox', {
    staticClass: "custom-control-warning",
    attrs: {
      "checked": _vm.showAgencyFee,
      "switch": ""
    },
    on: {
      "change": _vm.handleToggleShowAgencyFee
    }
  }), _c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.viewConfig.showAgService')) + " ")])], 1) : _vm._e(), _c('div', {
    staticClass: "d-flex-center justify-content-start mt-75"
  }, [_c('BFormCheckbox', {
    staticClass: "custom-control-warning",
    attrs: {
      "checked": _vm.showBookingClass,
      "switch": ""
    },
    on: {
      "change": _vm.handleToggleShowBookingClass
    }
  }), _c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.viewConfig.showBookingClass')) + " ")])], 1), _c('div', {
    staticClass: "d-flex-center justify-content-start mt-75"
  }, [_c('BFormCheckbox', {
    staticClass: "custom-control-warning",
    attrs: {
      "checked": _vm.showAircraft,
      "switch": ""
    },
    on: {
      "change": _vm.handleToggleShowAircraft
    }
  }), _c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.viewConfig.showAircraft')) + " ")])], 1), _c('ModalCustomServiceFee')], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }