<template>
  <div>
    <b-card
      header-class="p-1"
      no-body
    >
      <template #header>
        <div
          class="w-100 d-flex justify-content-between cursor-pointer"
          @click="handleToggleShow"
        >
          <div class="d-flex align-items-center">
            <IconSvg
              :src="require('@icons/filter_big.svg')"
              size="24px"
              class="mr-25"
            />
            <span class="font-weight-bolder">
              {{ $t('paginationText.showing') }}
            </span>
          </div>

          <IconSvg
            :src="require('@icons/triangle-down.svg')"
            blank-color="#ccc"
            class="rotate_class text-secondary"
            :style="{ transform: rotateStyleVisible, width: '12px' }"
          />
        </div>
      </template>

      <b-collapse
        v-model="isVisible"
        class="mx-1 mb-1"
      >
        <!-- ANCHOR CUSTOMER SERVICE FEE -->
        <div class="mb-1 text-center">
          <b-button
            v-b-modal.modal-customer-service-fee
            size="sm"
            variant="outline-warning"
            class="rounded-lg py-75"
          >
            <feather-icon
              icon="EditIcon"
              size="12"
              class="mr-50"
            />
            <span class="align-middle">{{ $t('flight.setServiceFee') }}</span>
          </b-button>
        </div>

        <!-- ANCHOR Sắp xếp theo -->
        <div>
          <h5>{{ $t('flight.sortBy') }}</h5>
          <v-select
            :value="sortBySelected"
            :reduce="val => val.value"
            label="label"
            :options="sortItinerariesTypeOptions"
            :clearable="false"
            @input="handleSelectSortBy"
          >
            <template #option="data">
              <small class="font-weight-bolder text-truncate">{{ $t(`flight.viewConfig.${data.label}`) }}</small>
            </template>

            <template #selected-option="data">
              <small class="font-weight-bolder text-truncate">{{ $t(`flight.viewConfig.${data.label}`) }}</small>
            </template>

            <template #spinner="{ loading }">
              <div
                v-if="loading"
                style="border-left-color: rgba(88, 151, 251, 0.71)"
                class="vs__spinner"
              />
            </template>

            <template #no-options>
              {{ $t('noOptions') }}
            </template>
          </v-select>
        </div>

        <!-- ANCHOR Hiển thị giá showPrice -->
        <div
          class="w-100 mt-75"
        >
          <h5 class="my-50">
            {{ $t('flight.showPrice') }}
          </h5>
          <v-select
            :value="showPrice"
            :reduce="val => val.value"
            class="w-100"
            label="label"
            :options="showPriceOptions"
            :clearable="false"
            @input="handleSelectShowPrice"
          >
            <template #option="data">
              <small class="font-weight-bolder text-truncate">{{ $t(`flight.viewConfig.${data.label}`) }}</small>
            </template>

            <template #selected-option="data">
              <small class="font-weight-bolder text-truncate">{{ $t(`flight.viewConfig.${data.label}`) }}</small>
            </template>

            <template #spinner="{ loading }">
              <div
                v-if="loading"
                style="border-left-color: rgba(88, 151, 251, 0.71)"
                class="vs__spinner"
              />
            </template>

            <template #no-options>
              {{ $t('noOptions') }}
            </template>
          </v-select>
        </div>
        <!-- ANCHOR Giá chiết khấu -->
        <div
          v-if="!isRoleF1 && (showPrice === 'TOTAL_FARE' || showPrice === 'NET_FARE')"
          class="d-flex-center justify-content-start mt-75"
        >
          <BFormCheckbox
            :checked="showPriceAfterDiscount"
            switch
            class="custom-control-warning"
            @change="handleSetShowPriceAfterDiscount"
          />
          <h5 class="mb-0">
            {{ $t('flight.viewConfig.discountedPrice') }}
          </h5>
        </div>
        <!-- ANCHOR Phí dịch vụ showServiceFee -->
        <div
          v-if="showPrice === 'TOTAL_FARE'"
          class="d-flex-center justify-content-start mt-75"
        >
          <BFormCheckbox
            :checked="showServiceFee"
            class="custom-control-warning"
            switch
            @change="handleToggleShowServiceFee"
          />
          <h5 class="mb-0">
            {{ $t('flight.viewConfig.showFeeService') }}
          </h5>
        </div>
        <!-- ANCHOR Phí xuất vé showAgencyFee -->
        <div
          v-if="!isRoleF1 && showPrice === 'TOTAL_FARE' && !getHideFee"
          class="d-flex-center justify-content-start mt-75"
        >
          <BFormCheckbox
            :checked="showAgencyFee"
            class="custom-control-warning"
            switch
            @change="handleToggleShowAgencyFee"
          />
          <h5 class="mb-0">
            {{ $t('flight.viewConfig.showAgService') }}
          </h5>
        </div>
        <!-- ANCHOR showBookingClass -->
        <div
          class="d-flex-center justify-content-start mt-75"
        >
          <BFormCheckbox
            :checked="showBookingClass"
            class="custom-control-warning"
            switch
            @change="handleToggleShowBookingClass"
          />
          <h5 class="mb-0">
            {{ $t('flight.viewConfig.showBookingClass') }}
          </h5>
        </div>

        <!-- ANCHOR showAircraft -->
        <div
          class="d-flex-center justify-content-start mt-75"
        >
          <BFormCheckbox
            :checked="showAircraft"
            class="custom-control-warning"
            switch
            @change="handleToggleShowAircraft"
          />
          <h5 class="mb-0">
            {{ $t('flight.viewConfig.showAircraft') }}
          </h5>
        </div>

        <ModalCustomServiceFee />

      </b-collapse>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BFormCheckbox, BButton, BCollapse,
} from 'bootstrap-vue'
import { computed, ref, watch } from '@vue/composition-api'
import vSelect from 'vue-select'

import { sortItinerariesTypeOptions, showPriceOptions } from '@/constants/selectOptions'
import store from '@/store'

// import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
// import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BCard,
    BCollapse,
    // AppCollapseItem,
    BFormCheckbox,
    vSelect,
    BButton,
    ModalCustomServiceFee: () => import('./ModalCustomServiceFee.vue'),
  },
  props: {
    isRoleF1: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    // ANCHOR Hiển thị giá
    // TICKET_FARE, NET_FARE, TOTAL_FARE, HIDE_FARE
    // Select 1 trong 4 option: = showPrice
    // - giá vé: chỉ hiển thị cột giá vé = TICKET_FARE
    // - giá Net: hiển thị giá net ko có phí dv = NET_FARE
    // - giá tổng (Default) : hiển thị giá đầy đủ phí dv = TOTAL_FARE
    // - ẩn giá: ẩn hết giá các loại = HIDE_FARE
    // Khi select Giá tổng thì được bật tắt 2 nút:
    // - ẩn hiện phí dịch vụ: ẩn thì phí dv cộng vào thuế và phí (sân bay) = showServiceFee
    // - ẩn hiện phí xuất vé: chỉ ẩn hiện ko tham gia vào công thức nào cả = showAgencyFee
    // Còn lại các select khác thì 2 nút bị disabled đi

    const sortBySelected = computed(() => store.getters['app-flight-v2/getSortItinerariesType']) // Sắp xếp theo
    const showPrice = computed(() => store.getters['app-flight-v2/getShowPrice']) // Hiển thị giá

    const showPriceAfterDiscount = computed(() => store.getters['app-flight-v2/getShowPriceAfterDiscount']) // Giá đã chiết khấu
    const showServiceFee = computed(() => store.getters['app-flight-v2/getShowServiceFee']) // phí dịch vụ
    const showAgencyFee = computed(() => store.getters['app-flight-v2/getShowAgencyFee']) // phí xuất vé
    const showBookingClass = computed(() => store.getters['app-flight-v2/getShowBookingClass']) // hạng vé
    const showAircraft = computed(() => store.getters['app-flight-v2/getShowAircraft']) // loại máy bay

    function handleSelectSortBy(val) {
      store.dispatch('app-flight-v2/setSortItinerariesType', val)
    }

    function handleSelectShowPrice(val) {
      store.dispatch('app-flight-v2/setShowPrice', val)
    }

    function handleSetShowPriceAfterDiscount() {
      this.$bvModal.show('modal-api-loading')
      setTimeout(async () => {
        await store.dispatch('app-flight-v2/setShowPriceAfterDiscount', !showPriceAfterDiscount.value)
        this.$bvModal.hide('modal-api-loading')
      }, 200)
    }

    function handleToggleShowServiceFee() {
      this.$bvModal.show('modal-api-loading')
      setTimeout(async () => {
        await store.dispatch('app-flight-v2/setShowServiceFee', !showServiceFee.value)
        this.$bvModal.hide('modal-api-loading')
      }, 200)
    }

    function handleToggleShowAgencyFee() {
      this.$bvModal.show('modal-api-loading')
      setTimeout(async () => {
        await store.dispatch('app-flight-v2/setShowAgencyFee', !showAgencyFee.value)
        this.$bvModal.hide('modal-api-loading')
      }, 200)
    }

    function handleToggleShowBookingClass() {
      this.$bvModal.show('modal-api-loading')
      setTimeout(async () => {
        await store.dispatch('app-flight-v2/setShowBookingClass', !showBookingClass.value)
        this.$bvModal.hide('modal-api-loading')
      }, 200)
    }

    function handleToggleShowAircraft() {
      this.$bvModal.show('modal-api-loading')
      setTimeout(async () => {
        await store.dispatch('app-flight-v2/setShowAircraft', !showAircraft.value)
        this.$bvModal.hide('modal-api-loading')
      }, 200)
    }

    const employeeConfig = computed(() => store.getters['userStore/getEmployeeConfig'])
    const getHideFee = computed(() => store.getters['userStore/getHideFee'])
    // eslint-disable-next-line no-unused-vars
    watch([employeeConfig, getHideFee], ([config, _]) => {
      if (config) {
        store.dispatch('app-flight-v2/setSortItinerariesType', config.sortType)
        store.dispatch('app-flight-v2/setShowPrice', config.showPriceOptions)
        store.dispatch('app-flight-v2/setShowPriceAfterDiscount', config.isShowPromotion)
        store.dispatch('app-flight-v2/setShowServiceFee', config.isShowFeeService)
        const isShowFee = getHideFee.value ? false : config.isShowFee
        store.dispatch('app-flight-v2/setShowAgencyFee', isShowFee)
        store.dispatch('app-flight-v2/setShowBookingClass', config.isShowBookingClass)
        store.dispatch('app-flight-v2/setShowAircraft', config.isShowAircraft)
      }
    }, { deep: true, immediate: true })

    const isVisible = ref(true)
    const rotateStyleVisible = computed(() => isVisible.value ? 'rotate(0deg)' : 'rotate(-180deg)')
    const handleToggleShow = () => {
      isVisible.value = !isVisible.value
    }

    return {
      showPriceAfterDiscount,
      handleSetShowPriceAfterDiscount,
      sortBySelected,
      sortItinerariesTypeOptions,
      handleSelectSortBy,
      showPrice,
      showServiceFee,
      showAgencyFee,
      showBookingClass,
      showAircraft,

      handleToggleShowServiceFee,
      handleToggleShowAgencyFee,
      handleToggleShowBookingClass,
      handleToggleShowAircraft,
      showPriceOptions,
      handleSelectShowPrice,
      getHideFee,

      isVisible,
      rotateStyleVisible,
      handleToggleShow,
    }
  },
}
</script>

<style lang="scss" scoped>
.rotate_class {
  transition: transform 0.3s ease;
}
</style>
